import styled from "styled-components";

const PrimaryButton = styled.button`
  border: none;
  background-color: ${(props) => props.theme.colors.accent.primary[500]};
  outline: none;
  border-radius: 8px;
  padding: 1rem;
  transition: transform 500ms;
  color: ${(props) => props.theme.colors.text.tertiary[500]};
  font-size: ${(props) => props.theme.fonts.size[props.size]};
  text-align: center;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.accent.primary[400]};
  }
  &:focus {
    background-color: ${(props) => props.theme.colors.accent.primary[400]};
  }
`;

PrimaryButton.defaultProps = {
  size: "small",
};

export default PrimaryButton;

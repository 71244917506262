import React from "react";
import Text from "components/common/Text";
import Space from "components/common/Space";
import PrimaryButton from "components/common/PrimaryButton";
import HeaderAvatar from "components/common/HeaderAvatar";
import styled, { useTheme } from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { motion } from "framer-motion";
import { Event, track } from "../../analytics";
import SEO from "components/layout/SEO";

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
`;

const ContactContainer = styled.div`
  width: 90vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const ConsultContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 1.5rem;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    min-width: 50vw;
  }
`;

const ConsultContent = styled.div``;

const HireContent = styled.div``;

const HireContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 1.5rem;
  @media only screen and (max-width: 600px) {
    min-width: 50vw;
  }
`;

const Divider = styled.div`
  height: 200px;
  background-color: ${(props) => props.theme.colors.accent.primary[100]};
  align-self: center;
  border-radius: 8px;
  width: 2px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 1.5rem;
  flex: 1;
`;

const IntentContainer = styled.div`
  height: 80px;
`;

const Contact = () => {
  const theme = useTheme();
  const accentColor = theme.colors.accent.primary[500];

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <SEO title="Contact - Devanshi Goradia" />
      <HeaderAvatar
        title="Reach out to Devanshi"
        subtitle="Clinical Psychologist"
      />
      <ContactContainer>
        <ConsultContainer>
          <ConsultContent>
            <Text
              textAlign="center"
              size="large"
              lineHeight="large"
              color={accentColor}
            >
              Consult
            </Text>
            <IntentContainer>
              <Text textAlign="center">
                Are you ready to take the first step towards being the best
                version of yourself?
              </Text>
            </IntentContainer>
            <ButtonContainer>
              <PrimaryButton
                as="a"
                target="_blank"
                href="mailto:dev7goradia@gmail.com?subject=Schedule first therapy consultation&body=Hi Devanshi. I would like to schedule my first therapy session with you. Can you let me know what time works best for you?"
              >
                Schedule Appointment
              </PrimaryButton>
            </ButtonContainer>
          </ConsultContent>
        </ConsultContainer>
        <Divider />
        <HireContainer>
          <HireContent>
            <Text
              textAlign="center"
              size="large"
              lineHeight="large"
              color={accentColor}
            >
              Hire
            </Text>
            <IntentContainer>
              <Text textAlign="center">
                Are you looking for a new member to join your consultation team?
              </Text>
            </IntentContainer>
            <ButtonContainer>
              <PrimaryButton
                as="a"
                target="_blank"
                href="https://drive.google.com/file/d/1XjnU_7dZsRXyGsY5naDvLmRCJdHW2Ou6/view?usp=sharing"
              >
                Download Resume
              </PrimaryButton>
            </ButtonContainer>
          </HireContent>
        </HireContainer>
      </ContactContainer>
    </Container>
  );
};

export default Contact;
